import React from 'react';
import { graphql } from 'gatsby';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import Layout from '../layouts/layout';
import { contentHeight } from '../utils/config';

const Actions = styled.ul`
  cursor: default;
  list-style: none;
  padding-left: 0;
  margin-left: 0;
`;

const ActionItem = styled.li`
  display: inline-block;
  padding: 0 1em 0 0;
  vertical-align: middle;
`;

const buttonStyle = css`
  text-transform: uppercase;
`;

const content = css`
  min-height: ${contentHeight}vh;
`;

const Contact = ({ data: { prismicContact } }) => {
  return (
    <Layout>
      <Container fluid css={content} className="my-4">
        <h1>{prismicContact.data.title.text}</h1>
        <Form
          name="contact"
          method="post"
          data-netlify
          data-netlify-honeypot="bot-field"
        >
          <Form.Control hidden name="bot-field" />
          <Form.Control hidden readOnly name="form-name" value="contact" />
          <Row>
            <Col sm="6">
              <Form.Group controlId="firstName">
                <Form.Control
                  size="lg"
                  name="firstName"
                  placeholder="First name"
                />
              </Form.Group>
            </Col>
            <Col sm="6">
              <Form.Group controlId="lastName">
                <Form.Control
                  size="lg"
                  name="lastName"
                  placeholder="Last name"
                />
              </Form.Group>
            </Col>
          </Row>
          <Form.Group controlId="email">
            <Form.Control size="lg" name="email" placeholder="Email" />
          </Form.Group>
          <Form.Group controlId="subject">
            <Form.Control size="lg" name="subject" placeholder="Subject" />
          </Form.Group>
          <Form.Group controlId="message">
            <Form.Control
              size="lg"
              name="message"
              as="textarea"
              rows="7"
              placeholder="Message"
            />
          </Form.Group>
          <Actions>
            <ActionItem>
              <Button
                css={buttonStyle}
                variant="dark"
                size="lg"
                as="input"
                type="submit"
                value="Submit"
              />
            </ActionItem>
            <ActionItem>
              <Button
                css={buttonStyle}
                variant="outline-dark"
                size="lg"
                as="input"
                type="reset"
                value="Clear"
              />
            </ActionItem>
          </Actions>
        </Form>
      </Container>
    </Layout>
  );
};

export default Contact;

export const pageQuery = graphql`
  query ContactQuery {
    prismicContact {
      data {
        title {
          text
        }
      }
    }
  }
`;
